import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from '../components/layout';
import Book from "../components/book";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import BlogPost from "../components/blog-post";

const IndexPage = ({ data }) => {
  return (
    <Layout title="Home">
      <div id="hero" style={{ display: 'grid' }} className="bg-primary">
        <StaticImage style={{ gridArea: '1/1', maxHeight: 600 }} src="../images/hero.png" layout="fullWidth" placeholder="none" alt="" formats={["auto", "webp", "avif"]}/>
        <section style={{ gridArea: '1/1', position: 'relative', placeItems: 'center', display: 'grid' }}>
          <Container className="text-center text-white">
            <StaticImage className="mb-4" width={250} placeholder="blurred" src="../images/avatar.png" alt="Avatar"/>
            <h1>{data.site.siteMetadata.title}</h1>
          </Container>
        </section>
      </div>
      {data.bioPage && <section className="bg-light" style={{ fontSize: '1.1rem' }}>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: data.bioPage.content.html }}/>
        </Container>
      </section>}
      <section>
        <Container>
          <h1 className="title">Books</h1>
          <Row>
            {
              data.allGraphCmsBook.nodes.map((node) => (
                <Col xs={6} md={3} key={node.id}>
                  <Book book={node}/>
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>

      {data.allGraphCmsBlogPost.totalCount > 0 && <section>
        <Container>
          <h1 className="text-center">Blog</h1>
          {
            data.allGraphCmsBlogPost.nodes.map((node) => (
              <BlogPost key={node.id} post={node}/>
            ))
          }
          {data.allGraphCmsBlogPost.totalCount > 3 && <Link to="/blog" className="btn btn-primary float-end my-3">See More</Link>}
        </Container>
      </section>}
    </Layout>
  )
}

export const query = graphql`
  query {
    allGraphCmsBlogPost(limit: 3, sort: {fields: date, order: DESC}) {
      nodes {
        id
        title
        date
        slug
        description
      }
      totalCount
    }
    allGraphCmsBook {
      nodes {
        id
        slug
        title
        shortDescription
        released
        coverImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    bioPage: graphCmsPage(title: {in: ["Bio", "Biography"]}) {
      title
      content {
        html
      }
    }
  }
`

export default IndexPage
